<template>
  <v-app-bar>
    <v-app-bar-title>Device details</v-app-bar-title>

    <v-spacer />

    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="mr-2"
          icon="mdi-package-check"
          :disabled="!markAsReturnedButtonEnabled"
          @click="returnDialog = true"
        />
      </template>
      Mark as returned
    </v-tooltip>

    <v-menu location="bottom" offset="8">
      <template #activator="{ props }">
        <v-tooltip location="bottom">
          <template #activator="{ props: tooltipProps }">
            <v-btn
              v-ripple="false"
              v-bind="{ ...props, ...tooltipProps }"
              icon="mdi-printer"
              :disabled="!showPrintReturnLabelButton"
            />
          </template>
          Print return label
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in returnLabelData" :key="index" @click="printReturnLabelSelection(index)">
          <v-list-item-title>{{ item.printButtonTitle }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-progress-circular v-if="isLoading" size="96" class="d-flex" style="margin: calc(50vh - 180px) auto 0 auto" />

  <!-- TODO: These can not be removed from DOM since it will cause a data loading loop, needs to be fixed! -->
  <v-container v-show="!isLoading">
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Device and warranty information for {{ ringOrChargerSerial }}</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="markAsReturnedButtonEnabled">
            This device can be marked as returned if there is a warranty case open
          </template>
          <template v-else>This device can not be marked as returned, maybe not paired to app yet</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn text="New search" color="primary" @click="$router.push('/rings')" />
      </v-col>
    </v-row>

    <template v-if="!deviceInfo">
      <v-alert class="mt-8" type="error">
        No ring or charger could be found with the given serial number, please check the serial number in the url and
        try again or make a new search.
      </v-alert>
    </template>

    <DevicesInfo v-show="deviceInfo" class="mt-8" :device-info="deviceInfo" />

    <RingWarrantyInfo
      v-show="warrantyData"
      class="mt-8"
      :warranty-data="warrantyData"
      :warranty-data-ring-replaced-by="warrantyDataRingReplacedBy"
      :warranty-data-ring-replacement-for="warrantyDataRingReplacementFor"
      :warranty-data-charger-replaced-by="warrantyDataChargerReplacedBy"
      :warranty-data-charger-replacement-for="warrantyDataChargerReplacementFor"
      :zendesk-note-for-ring="zendeskNoteForRing"
      :zendesk-note-for-charger="zendeskNoteForRing"
    />

    <RingHistoryInfo v-show="deviceInfo" class="mt-8" :ring-serial="ringOrChargerSerial" />
  </v-container>

  <PrintReturnLabel ref="returnLabel" :return-label-data="selectedReturnLabelData" />

  <MarkItemReturned
    :open="returnDialog"
    :warranty-data="warrantyData"
    :return-dialog-data="returnDialogData"
    :refresh-device-serial="ringOrChargerSerial"
    @close="returnDialog = false"
    @update="updateInfo()"
  />
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { PrintReturnLabel } from '#views/devices/PrintReturnLabel.vue'

  import { extractDeviceInfoFromRingAndWarrantyDetails } from '#utils/device-info/extract'
  import {
    extractMarkAsReturnedDataFromWarrantyData,
    extractReturnDialogDataFromMarkAsReturnedData,
    extractReturnLabelDataFromWarrantyData,
  } from '#utils/mark-returned/extract'

  import { AppStore, RingsStore } from '#stores'

  import { DeviceInfo, Nullable, ReturnDialogData, ReturnLabelData } from '#types'

  @Component({})
  class DeviceDetailsView extends Vue {
    declare public $refs: {
      returnLabel: PrintReturnLabel
    }
    /**
     * This property contains either ring or charger serial number.
     * We can fetch device/warranty details with either.
     */
    @Prop() public ringOrChargerSerial!: string

    public returnDialog = false

    public deviceInfo: Nullable<DeviceInfo> = null
    public zendeskNoteForRing: Nullable<string> = null

    public returnLabelData: Nullable<ReturnLabelData[]> = null
    public returnDialogData: Nullable<ReturnDialogData> = null

    public selectedReturnLabelData: Nullable<ReturnLabelData> = null

    protected readonly appStore = new AppStore()
    protected readonly ringsStore = new RingsStore()

    public get isLoading() {
      return this.ringsStore.dataWait || this.ringsStore.waitingForData()
    }

    public get selectedRing() {
      return this.ringsStore.ring
    }

    public get warrantyData() {
      return this.ringsStore.warrantyData
    }

    public get showPrintReturnLabelButton() {
      return this.returnLabelData && this.returnLabelData.length > 0
    }

    public get markAsReturnedButtonEnabled() {
      if (this.isLoading) {
        return false
      }

      if (!this.returnDialogData) {
        return false
      }

      if (
        this.returnDialogData.ring.allowReturn ||
        this.returnDialogData.cable.allowReturn ||
        this.returnDialogData.charger.allowReturn
      ) {
        return true
      }

      return false
    }

    public get warrantyDataRingReplacedBy() {
      return this.ringsStore.warrantyDataRingReplacedBy
    }

    public get warrantyDataRingReplacementFor() {
      return this.ringsStore.warrantyDataRingReplacementFor
    }

    public get warrantyDataChargerReplacedBy() {
      return this.ringsStore.warrantyDataChargerReplacedBy
    }

    public get warrantyDataChargerReplacementFor() {
      return this.ringsStore.warrantyDataChargerReplacementFor
    }

    @Watch('selectedRing', { immediate: false })
    protected async selectedRingChanged() {
      this.deviceInfo = extractDeviceInfoFromRingAndWarrantyDetails(this.selectedRing, this.warrantyData)
    }

    @Watch('warrantyData', { immediate: false })
    protected async warrantyDataChanged() {
      if (this.warrantyData) {
        await this.updateZendeskNoteForRing()

        this.returnDialogData = extractReturnDialogDataFromMarkAsReturnedData(
          extractMarkAsReturnedDataFromWarrantyData(this.warrantyData),
        )

        this.deviceInfo = extractDeviceInfoFromRingAndWarrantyDetails(this.selectedRing, this.warrantyData)

        this.returnLabelData = extractReturnLabelDataFromWarrantyData(this.warrantyData, this.zendeskNoteForRing)
      } else {
        this.returnLabelData = null
        this.returnDialogData = null
      }
    }

    @Watch('ringOrChargerSerial', { immediate: true })
    protected async ringOrChargerSerialChanged() {
      this.reset()

      await this.updateInfo()
    }

    /**
     * Reset component's UI back to initial state
     */
    public reset() {
      this.deviceInfo = null
      this.returnLabelData = null
      this.returnDialogData = null
      this.zendeskNoteForRing = null
      this.selectedReturnLabelData = null
    }

    public async updateInfo() {
      this.returnDialog = false

      this.ringsStore.errorMarkReturned = ''

      await this.ringsStore.getRingDetails(this.ringOrChargerSerial)
      await this.ringsStore.getWarrantyData({ serial: this.ringOrChargerSerial, initial: true })
    }

    public printReturnLabel() {
      this.$refs.returnLabel.openPrintPreview()
    }

    public printReturnLabelSelection(printLabelIndex: number) {
      this.selectedReturnLabelData = this.returnLabelData && this.returnLabelData[printLabelIndex]

      this.$refs.returnLabel.openPrintPreview()
    }

    private async updateZendeskNoteForRing() {
      /**
       * TODO: Currently only way to get Zendesk notes based on ring or charger serial is to use the ring
       * search endpoint. This is a ugly hack, and in the future we should improve this so that backend
       * returns data in easily digestible format. Using search causes extra data loading needs as well.
       */

      let searchTerm: string | null = null
      let searchType: 'ringSerial' | 'chargerSerial' | null = null

      if (this.warrantyData?.ringset.ringSerialNumber) {
        searchType = 'ringSerial'
        searchTerm = this.warrantyData.ringset.ringSerialNumber
      } else if (this.warrantyData?.ringset.chargerSerialNumber) {
        searchType = 'chargerSerial'
        searchTerm = this.warrantyData.ringset.chargerSerialNumber
      }

      if (searchTerm && searchType) {
        await this.ringsStore.search({
          search: searchTerm,
          searchType: searchType,
          partialSearch: false,
          pageSize: 500,
        })

        if (
          this.ringsStore.ringSearchResult?.length > 0 &&
          this.ringsStore.ringSearchResult[0].zendeskNotes.length > 0 &&
          (this.ringsStore.ringSearchResult[0].ringSerialNumber === this.warrantyData?.ringset.ringSerialNumber ||
            this.ringsStore.ringSearchResult[0].chargerSerialNumber === this.warrantyData?.ringset.chargerSerialNumber)
        ) {
          this.zendeskNoteForRing = this.ringsStore.ringSearchResult[0].zendeskNotes[0]
        }
      }
    }
  }

  export default toNative(DeviceDetailsView)
</script>

<style lang="scss" scoped>
  .print {
    td {
      padding: 0 !important;
      height: 1.4rem !important;
      border: 1px solid rgba(88, 87, 87, 0.25) !important;
      font-size: 1rem !important;
      line-height: 1rem !important;
    }
  }

  :deep(.v-dialog) {
    .v-card {
      box-shadow: none !important;
    }
  }
</style>

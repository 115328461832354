import { UserStore } from './user'

import JSON5 from 'json5'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { genericGraphChipColor, graphDayThreshold } from '#views/members/constants'

import { cacheQueryClient } from '#utils/cache'
import { getDaysBetween } from '#utils/datetime/calculate'
import { extractCacheWindowsFromGraphOptions } from '#utils/generic-graph/cache-windows'
import { extractFilterWeightsFromGraphOptions } from '#utils/generic-graph/weights'
import { ErrorType } from '#utils/request/requestError'
import { camelCaseToWords } from '#utils/utils'

import { BaseStore } from '#stores/base'

import { AvailableGraphs, Categories, GraphCacheWindows, GraphFilterWeights, GraphOptions, Notifications } from '#types'

@Store
export class GenericGraphStore extends BaseStore {
  public dataWait: any = {}

  public notifications: Notifications = {}

  public genericGraphParts: any = {}

  public graphOptions: GraphOptions | null = null
  public cacheWindows: GraphCacheWindows | null = null
  public filterWeights: GraphFilterWeights | null = null

  public get availableGraphs(): ({ header: string } | AvailableGraphs | { divider: boolean } | any)[][] {
    const data: Categories | undefined = this.graphOptions?.categories
    const graphs: ({ header: string } | AvailableGraphs | { divider: boolean } | any)[][] = []
    if (data) {
      for (const key in data) {
        const tempArr: ({ header: string } | AvailableGraphs | { divider: boolean })[] = []
        const headerRow = { header: camelCaseToWords(key) }
        tempArr.push(headerRow)
        for (const itemKey in data[key]) {
          if (data[key][itemKey].show_in_troubleshooter) {
            tempArr.push({
              name: data[key][itemKey].name,
              description: data[key][itemKey].description,
              usageInfo: data[key][itemKey].usage_info,
              graphType: data[key][itemKey].graph_type,
              allowed: data[key][itemKey].allowed,
              value: itemKey,
              color: genericGraphChipColor,
              weight: data[key][itemKey].weight,
              cacheWindow: data[key][itemKey].cacheWindow,
              needsRingSerial: data[key][itemKey].needsRingSerial,
            })
          }
        }
        tempArr.push({ divider: true })
        graphs.push(tempArr)
      }
    }
    return graphs
  }

  public get serialRequiredGraphs(): string[] {
    const data: Categories | undefined = this.graphOptions?.categories
    const graphs: string[] = []
    if (data) {
      for (const key in data) {
        for (const itemKey in data[key]) {
          if (data[key][itemKey].needsRingSerial) {
            graphs.push(itemKey)
          }
        }
      }
    }
    return graphs
  }

  public get preDefinedGroups() {
    return this.graphOptions?.groups
  }

  private getGraphCacheWindow(graph: string): number {
    return this.cacheWindows ? this.cacheWindows[graph] : graphDayThreshold
  }

  /**
   * Get list of available graphs in outo generic graph endpoint
   */
  public setGenericGraphParts(data: { mode: string; component: string; graph: string; value?: any }) {
    const genericGraphParts = this.genericGraphParts
    if (!Object.keys(genericGraphParts).includes(data.component)) {
      genericGraphParts[data.component] = {}
    }
    if (data.mode == 'add') {
      genericGraphParts[data.component][data.graph] = data.value
    } else if (data.mode == 'remove') {
      delete genericGraphParts[data.component][data.graph]
    }
    this.genericGraphParts = Object.keys(genericGraphParts).length ? { ...genericGraphParts } : {}
  }

  public setDataWait(data: { component: string; value: boolean }) {
    const dataWait = this.dataWait
    dataWait[data.component] = data.value
    this.dataWait = { ...dataWait }
  }

  public async getGraphOptions(params: { uuid: string }) {
    const userStore = new UserStore()
    this.graphOptions = null
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${params.uuid}/graphs` },
      'getGraphOptions',
      params.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    if (response?.data) {
      this.graphOptions = response.data
      this.filterWeights = extractFilterWeightsFromGraphOptions(response.data)
      this.cacheWindows = extractCacheWindowsFromGraphOptions(response.data)
    }
  }

  /**
   * Get generic graphs by graph names
   */
  public async loadGenericGraphParts(params: {
    from: string
    to: string
    uuid: string
    ringSerial: string
    graph: string
    timeZone: number
    callingComponent: string
  }) {
    this.genericGraphParts = {}
    const userStore = new UserStore()
    const responses: any[] = []
    let responseData: any[] = []

    let startDate = new Date(params.from)
    const endDate = new Date(params.to)
    let days = getDaysBetween(startDate, endDate) + 1
    const cacheWindow = this.getGraphCacheWindow(params.graph)
    const url = `/api/v1/users/${params.uuid}/graph`
    const requestDays = days > cacheWindow ? cacheWindow : days
    const requiresSerial = this.serialRequiredGraphs.includes(params.graph)
    while (startDate <= endDate) {
      const urlParams = {
        fromDate: startDate.toISOString().split('T')[0],
        days: requestDays,
        graph: params.graph,
        graphType: 'plotlyNative',
        timeZone: params.timeZone,
        ringSerial: requiresSerial ? params.ringSerial : undefined,
      }
      let requestSource =
        urlParams.graph +
        '-' +
        params.callingComponent +
        '-' +
        urlParams.fromDate +
        '-tz' +
        urlParams.timeZone +
        '-days' +
        urlParams.days

      if (requiresSerial) {
        requestSource += '-sn-' + params.ringSerial
      }

      const response: AxiosResponse = await cacheQueryClient.fetchQuery({
        queryKey: [url, urlParams],
        queryFn: async () => {
          return this.makeRequest(
            {
              method: 'get',
              url: url,
              params: urlParams,
            },
            requestSource,
            params.uuid,
            userStore.user?.uuid,
          )
            .then((value: AxiosResponse | null) => {
              return value
            })
            .catch((axiosError: AxiosError) => {
              const resError = this.handleRequestError(axiosError, requestSource)
              if (resError.userMessage) {
                let userFriendlyMessage =
                  'Error while fetching "' + params.graph + '" graph. Message: ' + resError.userMessage + '.'

                /**
                 * This logic should be probably moved inside requestError? First we need to figure out how to generate
                 * context specific (in this case graph) error messages that are actually helpful for users.
                 */
                if (resError.type == ErrorType.PAYLOAD_TOO_LARGE && resError.details.statusCode == 413) {
                  userFriendlyMessage +=
                    ' Possible reason is that graph contains too much data. Try to narrow the timeframe.'
                }

                this.notifications[params.graph] = {
                  severity: 'error',
                  message: userFriendlyMessage,
                }
              }
              return {}
            })
        },
      })

      if (!response) {
        return Promise.reject('Request aborted, parameters: ' + JSON.stringify(params))
      }

      if (response.data?.graph) {
        // Decode base64 response
        responses.push(JSON5.parse(window.atob(response.data.graph)))
      }
      startDate = response.data?.nextValidStartDate ? new Date(response.data?.nextValidStartDate) : endDate
      days = getDaysBetween(new Date(response.data?.nextValidStartDate), endDate) + 1

      if (!days) {
        break
      }
    }
    responseData = responses

    this.setGenericGraphParts({
      mode: 'add',
      component: params.callingComponent,
      graph: params.graph,
      value: responseData,
    })
  }

  /**
   * Remove unselected graphs from the genericGraphParts-state
   */
  public async removeUnselectedGenericGraphParts(params: { callingComponent: string; graphs: string[] }) {
    if (!Object.keys(this.genericGraphParts).includes(params.callingComponent)) {
      return
    }
    const filters = params.graphs
    for (const graph of Object.keys(this.genericGraphParts[params.callingComponent])) {
      if (!filters.includes(graph)) {
        this.setGenericGraphParts({ mode: 'remove', component: params.callingComponent, graph: graph })
      }
    }
  }

  /**
   * Remove all graph data.
   * @param params Contains source and the component which the generic graphs are called from
   */
  public removeGraphData(params: { source: string; callingComponent: string }) {
    this.graphOptions = null
    this.setGenericGraphParts({ mode: 'remove', component: params.callingComponent, graph: params.source })
  }
}

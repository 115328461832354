<template>
  <v-card>
    <v-card-title>No open Zendesk ticket found</v-card-title>

    <v-card-text>
      <span>
        No open Zendesk ticket found for the member account with uuid {{ uuid }}.
        <br />
        <br />
        In order to ensure data privacy a valid reason for viewing the member account must be given when the member has
        not contacted us.
        <br />
        <br />

        <strong>This action will be audit logged.</strong>
        <br />
        <br />
        Please provide the following details and confirm that member has given you consent to view their data:
        <br />
        <br />
        <strong>The reason for viewing this member.</strong>
      </span>

      <v-select
        v-model="customReasonsSelection"
        class="mt-5"
        :items="customReasonsSelectionList"
        :no-data-text="'No saved reasons available'"
        label="Select your saved custom reason"
        @update:model-value="updateWithSavedReason()"
      />

      <v-select
        v-model="mainReason"
        class="mt-5"
        data-cy="viewingReason"
        :items="mainReasonsList"
        :rules="[rules.required]"
        label="Select the reason"
        hide-details="auto"
        @update:model-value="handleMainReasonChange()"
      />

      <v-select
        v-if="extraReasonIsRequired"
        v-model="extraReason"
        class="mt-5"
        :items="extraReasonsList"
        :rules="[rules.required]"
        label="Select reason extra info"
        hide-details="auto"
        @update:model-value="handleExtraReasonChange()"
      />

      <!-- Have to use 2 text fields here, because otherwise the "required" message will stay even for non-required fields -->
      <v-text-field
        v-if="textExplanationIsRequired"
        v-model="textExplanation"
        :label="textExplanationLabel"
        :rules="[rules.counter, rules.required]"
        counter
        maxlength="100"
        hide-details="auto"
      />
      <v-text-field
        v-else
        v-model="textExplanation"
        :label="textExplanationLabel"
        :rules="[rules.counter]"
        counter
        maxlength="100"
        hide-details="auto"
      />
    </v-card-text>

    <v-card-actions class="px-4">
      <v-btn variant="text" :disabled="reasonIsNotSaveable" @click="saveCustomReason()">
        {{ saveCustomReasonButtonName }}
      </v-btn>

      <v-spacer />
      <v-btn variant="text" @click="$router.back()">Cancel</v-btn>
      <v-btn variant="text" color="red" data-cy="confirm" :disabled="isInvalidAgentReason" @click="confirmUserSearch()">
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { agentReasons, defaultTextExplanationLabel } from '#views/members/constants'

  import { AppStore, UserStore } from '#stores'

  import { AgentReason, ExtraAgentReason } from '#types'

  @Component
  class UserSearchConsent extends Vue {
    @Prop() public zdTicketStatus!: boolean
    @Prop() public uuid!: string
    @Prop() public agentReason!: string

    public appStore = new AppStore()
    public userStore = new UserStore()

    public mainReason: AgentReason = agentReasons[0]
    public extraReason: ExtraAgentReason | null = null
    public textExplanation = ''

    public customReasonsSelection: string = ''
    public customAgentReasons: {
      [key: string]: (string | null)[]
    } = {}

    public rules = {
      required: (value: string) => !!value || 'Required.',
      counter: (value: string) => value.length <= 100 || 'Max 100 characters',
    }
    public saveCustomReasonButtonName = 'Save Custom Reason'

    @Emit('update:zdTicketStatus')
    public updateZdTicketStatus(status: boolean) {
      return status
    }

    @Emit('update:agentReason')
    public updateAgentReason(reason: string) {
      return reason
    }

    public mounted() {
      const reasonString = localStorage.getItem('previousAgentReasonObject')
      if (reasonString) {
        const previousReason = JSON.parse(reasonString)
        this.setAllFields(previousReason.mainReason, previousReason.extraReason, previousReason.textExplanation)
      }

      const storedReasons = localStorage.getItem('customAgentReasons')
      this.customAgentReasons = storedReasons ? JSON.parse(storedReasons) : {}
    }

    public get mainReasonsList() {
      return agentReasons.map((reason) => ({ title: reason.mainReason, value: reason }))
    }

    public get extraReasonsList() {
      return this.mainReason.extraReasons?.map((extra) => ({ title: extra.reason, value: extra }))
    }

    public get extraReasonIsRequired() {
      return this.mainReason.extraReasons && this.mainReason.extraReasons?.length > 0
    }

    public get textExplanationIsRequired() {
      return this.mainReason.requiresTextExplanation || this.extraReason?.requiresTextExplanation
    }

    public get customReasonsSelectionList(): string[] {
      return Object.keys(this.customAgentReasons)
    }

    public get textExplanationLabel(): string {
      if (this.extraReason?.requiresTextExplanation) {
        return this.extraReason.textExplanationLabel || defaultTextExplanationLabel
      }
      if (this.mainReason.requiresTextExplanation) {
        return this.mainReason.textExplanationLabel || defaultTextExplanationLabel
      }
      return 'Additional details (optional)'
    }

    public get isInvalidAgentReason(): boolean {
      return Boolean(
        !this.mainReason ||
          (this.extraReasonIsRequired && !this.extraReason) ||
          (this.textExplanationIsRequired && !this.textExplanation),
      )
    }

    private get agentReasonText(): string {
      const mainInfoText = this.mainReason.mainReason

      const extraInfoText = this.extraReason ? ` Details: ${this.extraReason.reason};` : ''

      const textExplanationText = this.textExplanation ? ` ${this.textExplanationLabel}: ${this.textExplanation}` : ''

      return `${mainInfoText};${extraInfoText}${textExplanationText}`
    }

    public get reasonIsNotSaveable(): boolean {
      return !this.mainReason || !this.textExplanation
    }

    public updateWithSavedReason() {
      if (this.customReasonsSelection in this.customAgentReasons) {
        const [dropdown, text] = this.customAgentReasons[this.customReasonsSelection]
        this.setAllFields(this.customReasonsSelection, dropdown, text)
      }
    }

    protected setAllFields(mainReason: string, extraReason: string | null, textExplanation: string | null) {
      const newMainReason = agentReasons.find((reason) => reason.mainReason === mainReason)
      if (newMainReason) {
        this.mainReason = newMainReason
        this.extraReason = newMainReason.extraReasons?.find((extra: any) => extra.reason === extraReason) || null
        this.textExplanation = textExplanation || ''
      }
    }

    public handleMainReasonChange() {
      this.extraReason = null
      this.textExplanation = ''
    }

    public handleExtraReasonChange() {
      this.textExplanation = ''
    }

    @Watch('mainReason')
    @Watch('extraReason')
    @Watch('textExplanation')
    protected onAnyFieldChanged() {
      this.saveCustomReasonButtonName = 'Save Custom Reason'
    }

    public confirmUserSearch() {
      this.setAgentReason(this.agentReasonText)
      this.userStore.setSensitiveDataVisible(false, '').then(() => {
        this.userStore.getUser({
          user: this.uuid,
          agentReason: this.agentReasonText,
          hideRingNames: [],
        })
      })

      const newPreviousReason = {
        mainReason: this.mainReason.mainReason,
        extraReason: this.extraReason?.reason,
        textExplanation: this.textExplanation,
      }
      localStorage.setItem('previousAgentReasonObject', JSON.stringify(newPreviousReason))

      this.updateZdTicketStatus(false)
      this.updateAgentReason(this.agentReasonText)
    }

    private setAgentReason(reasonText: string) {
      localStorage.setItem('agentReason', reasonText)
      localStorage.setItem('previousAgentReason', reasonText)
    }

    public saveCustomReason() {
      const reasons = this.customAgentReasons
      reasons[this.mainReason.mainReason] = [this.extraReason?.reason || null, this.textExplanation]
      localStorage.setItem('customAgentReasons', JSON.stringify(reasons))
      this.saveCustomReasonButtonName = 'Saved'
    }
  }

  export default toNative(UserSearchConsent)
</script>

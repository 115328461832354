<template>
  <template v-if="$route.params.uuid">
    <v-tooltip v-if="!timezoneToggleAllowed" location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-clock-time-five" color="red" />
      </template>
      <span>The URL has member's timezone in use, but you don't have the required permission!</span>
    </v-tooltip>
    <v-tooltip v-else-if="!utcInUse()" location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-clock-time-five" color="green" @click="toggleTimezoneSetting(false)" />
      </template>
      <span>Member's time: {{ formatDateTime(Date.now(), 'HH:mm - D MMM YYYY') }}</span>
    </v-tooltip>
    <v-tooltip v-else location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-clock-time-five" color="blue" @click="toggleTimezoneSetting(false)" />
      </template>
      <span>UTC time: {{ formatDateTime(Date.now(), 'HH:mm - D MMM YYYY') }}</span>
    </v-tooltip>
  </template>

  <template v-if="$route.params.uuid && (personal || rights.includes('allowSensitiveDataAccess'))">
    <v-tooltip v-if="sensitiveDataVisible" location="bottom end" offset="8">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-eye" color="green" @click="hideSensitiveData()" />
      </template>
      <span>Sensitive data visible</span>
    </v-tooltip>
    <v-tooltip v-else location="bottom end" offset="8">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-eye-off" color="blue" @click="openSensitiveDataDialog()" />
      </template>
      <span>Sensitive data hidden</span>
    </v-tooltip>
  </template>

  <v-menu v-if="personal || rights.includes('allowDataDeletionAccess')" left offset="8">
    <template #activator="{ props }">
      <v-tooltip location="bottom end">
        <template #activator="{ props: tooltipProps }">
          <v-btn v-bind="{ ...props, ...tooltipProps }" icon="mdi-account-details-outline" />
        </template>
        <span>Account actions</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-if="currentEmail && rights.includes('allowPersonalDataAccess')"
        :disabled="currentEmail.verified || user.isVerificationBypassed"
        :prepend-icon="'mdi-email-sync-outline'"
        @click="sendVerificationEmail()"
      >
        <v-list-item-title>Re-send verification email</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="rights.includes('allowDataDeletionAccess')"
        :prepend-icon="'mdi-email-edit-outline'"
        @click="changeEmailClick()"
      >
        <v-list-item-title>Change member email address</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$featureEnabled('remoteAppActions')"
        :prepend-icon="'mdi-remote'"
        @click="remoteAppActionDialog = true"
      >
        <v-list-item-title>Run app action on users behalf</v-list-item-title>
      </v-list-item>

      <v-divider v-if="personal || rights.includes('allowDataDeletionAccess')" />

      <v-list-item
        v-if="personal || rights.includes('allowDataDeletionAccess')"
        :disabled="!!user.deleteTicket"
        :prepend-icon="'mdi-delete-clock-outline'"
        @click="deleteUser = true"
      >
        <v-list-item-title class="text-red">Mark member to be deleted</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <Teleport defer to="#accountAlerts">
    <v-alert v-if="deletionAlert" tile class="px-5 mb-6 v-sheet--tile highlight-alert" type="warning" color="error">
      <div class="d-flex flex-row align-center">
        <span class="alert-message">{{ deletionAlert }}</span>
        <v-spacer />
        <v-btn
          v-if="personal || rights.includes('allowDataDeletionAccess')"
          class="text-none ml-4"
          size="small"
          variant="flat"
          color="error"
          @click="confirmCancelDelete()"
        >
          UNDO
        </v-btn>
      </div>
    </v-alert>
  </Teleport>

  <v-dialog v-if="changeEmail" v-model="changeEmail" persistent width="600">
    <v-card v-if="changeDeletedEmail" :loading="changingEmail">
      <v-card-title>Change member email address</v-card-title>

      <v-card-text>
        <p>Account is pending deletion and NOT confirmed by the member.</p>
        <p>The email will be freed automatically once the deletion request is confirmed by the member</p>
        <p>Are you sure you want to change the email?</p>

        <v-checkbox v-if="changeDeletedEmail" v-model="undoDeletion" label="Undo pending user deletion" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="resetEmailChange()">Cancel</v-btn>
        <v-btn color="error" @click="changeDeletedEmail = false">Continue</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else :loading="changingEmail">
      <v-card-title>Change member email address</v-card-title>

      <v-card-text>
        <div>
          This will change the member email from
          <b>{{ user.email }}</b>
          to the email given below. New verification email will be send if the checkbox below is checked.
        </div>

        <v-form v-model="emailValid">
          <v-text-field
            v-model="newEmail"
            class="mt-4 mb-n2"
            label="New email address"
            hide-details="auto"
            :rules="emailRules"
            :disabled="changingEmail"
          />

          <v-checkbox
            v-model="sendVerification"
            :disabled="changingEmail"
            label="Send verification email to the new email address"
          />

          <v-alert
            v-if="changingError"
            dense
            variant="text"
            tile
            type="error"
            class="mx-n4 mb-n4"
            style="position: static !important"
          >
            {{ changingError }}
          </v-alert>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="resetEmailChange()">Cancel</v-btn>
        <v-btn color="blue" :disabled="!emailValid || changingEmail" @click="changeUserEmail()">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-if="deleteUser" v-model="deleteUser" width="700">
    <v-card>
      <v-card-title>Mark member for deletion?</v-card-title>

      <v-card-text>
        This will mark the member and all member's data to be deleted after two weeks from the confirmation.

        <div v-if="user.email?.includes('@ouraring.com.disabled')" class="mt-3 text-error">
          Since this account has already once been marked for deletion it will be placed into deletion queue without
          confirmation from the member.
        </div>
        <div v-else-if="rings.length === 0" class="mt-3">
          Since the member doesn't have any rings connected to the account, deletion can proceed without requiring
          confirmation from the member.
          <v-checkbox v-model="deleteRequested" label="Delete without confirmation" />
        </div>
      </v-card-text>

      <v-alert v-if="deletionError" variant="text" type="error" class="mx-n4 mb-n4">
        {{ deletionError }}
      </v-alert>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelDelete()">Cancel</v-btn>
        <v-btn color="red" @click="markForDeletion()">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-if="sensitiveDataDialog" v-model="sensitiveDataDialog" persistent width="600">
    <v-card>
      <v-card-title class="headline grey lighten-2">Show Sensitive Data</v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <p>
              You're requesting to view
              <strong>{{ user?.email }}</strong>
              sensitive information.
              <br />
              Viewing sensitive information, such as personal health information, requires permission (given when a
              customer contacts customer support) and a reason specific to supporting this member's issue.
              <strong>
                For this reason, we're logging sensitive information requests, and we will be reviewing them.
              </strong>
              Please contact your manager or the Security and Compliance team on slack if you have questions about
              permissions or reasons for viewing sensitive information.
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-checkbox v-model="disableOneWeek" label="Disable this notification for one week" />

        <v-spacer />

        <v-btn @click="sensitiveDataDialog = false">Cancel</v-btn>
        <v-btn color="error" @click="confirmSensitiveData()">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-if="remoteAppActionDialog" v-model="remoteAppActionDialog" width="500">
    <v-card>
      <v-card-title>Run app action on member's behalf</v-card-title>

      <v-card-text>
        <v-select
          v-model="remoteAppAction"
          :items="appActions"
          label="Action to run"
          @update:model-value="remoteAppActionText = appActionTexts[$event] || ''"
        />

        <v-switch
          v-if="remoteAppAction !== 'message'"
          v-model="remoteAppActionConfirm"
          label="Ask confirmation from the member"
        />

        <v-text-field v-else v-model="remoteAppActionTitle" label="Message title" />

        <v-textarea
          v-model="remoteAppActionText"
          rows="2"
          class="mt-2"
          :disabled="!remoteAppActionConfirm && remoteAppAction !== 'message'"
          :label="remoteAppAction !== 'message' ? 'Confirmation dialog text' : 'Message to show to the user'"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="remoteAppActionDialog = false">Cancel</v-btn>
        <v-btn color="green" @click="sendAppActionMessage()">Run</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { appActionTexts, appActions, deleteStatus } from '#views/members/constants'

  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { AppStore, RemoteStore, UserStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class AccountActions extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public rings!: Ring[]

    @Prop() public personal!: boolean

    public newEmail = ''

    public deletionAlert = ''
    public deletionError = ''
    public changingError = ''

    public emailValid = false
    public deleteUser = false
    public changeEmail = false
    public undoDeletion = false
    public changingEmail = false
    public disableOneWeek = false
    public deleteRequested = false
    public sendVerification = true
    public sensitiveDataDialog = false
    public remoteAppActionDialog = false
    public remoteAppActionConfirm = true

    public remoteAppAction = 'backup'
    public remoteAppActionText = appActionTexts['backup']
    public remoteAppActionTitle = 'Message from Oura support'

    public changeDeletedEmail: boolean | null = false

    public appStore = new AppStore()
    public userStore = new UserStore()
    public remoteStore = new RemoteStore()

    public env = import.meta.env.VITE_APP_ENV

    public readonly appActions = appActions
    public readonly appActionTexts = appActionTexts

    public emailRules = [(v: string) => isEmailValid(v, this.env) || getEmailValidationErrorMessage(this.env)]

    public get rights() {
      return this.appStore.activeRights
    }

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get timezoneToggleAllowed() {
      return this.prefsStore.timezoneAllowed()
    }

    public get currentEmail() {
      const currentEmail = this.user.emails.filter((email) => email.currentEmail)
      return currentEmail.length > 0 ? currentEmail[0] : null
    }

    @Watch('user', { immediate: true })
    protected userChanged() {
      this.checkAccountDeletion()
    }

    public cancelDelete() {
      this.deleteUser = false
      this.deleteRequested = false
    }

    public markForDeletion() {
      this.userStore
        .createDeleteTicket({
          user: this.user.uuid,
          status: this.deleteRequested || this.user.email?.includes('@ouraring.com.disabled') ? 'requested' : 'initial',
        })
        .then((response) => {
          this.deletionError = response?.status === 200 ? null : response?.data?.detail

          if (response?.status === 200) {
            this.deleteUser = false
            this.deleteRequested = false
          }
        })
    }

    public async confirmCancelDelete() {
      const confirm = await this.$confirm(
        'Cancel member deletion?',
        'This will cancel member deletion completely, no data will be deleted from Oura systems.',
      )

      if (confirm) {
        this.userStore.removeDeleteTicket(this.user?.uuid || '')
      }
    }

    public changeUserEmail() {
      this.changingError = ''

      this.changingEmail = true

      this.userStore
        .changeUserEmail({
          uuid: this.user.uuid,
          email: this.newEmail,
          verify: this.sendVerification,
        })
        .then(() => {
          this.changingEmail = false

          const requestError = this.userStore.getRequestError('changeUserEmail')

          if (requestError) {
            this.changingError = requestError.userMessage ?? ''
          } else {
            this.newEmail = ''
            this.changingError = ''

            this.changeEmail = false

            if (this.undoDeletion) {
              this.userStore.removeDeleteTicket(this.user.uuid)
            }
          }
        })
    }

    public resetEmailChange() {
      this.changingError = ''

      this.changeEmail = false
      this.changeDeletedEmail = false
    }

    public changeEmailClick() {
      this.changeEmail = true

      this.changeDeletedEmail = this.user.flags.markedForDeletion || this.user.flags.queuedForDeletion

      if (this.changeDeletedEmail) {
        this.undoDeletion = true
      }
    }

    public hideSensitiveData() {
      const userUuid = this.user?.uuid

      if (userUuid) {
        this.userStore.setSensitiveDataVisible(false).then(() => {
          this.userStore.getUser({
            user: userUuid,
            agentReason: localStorage.getItem('agentReason') ?? '',
            hideRingNames: [],
          })
        })
      }
    }

    public showSensitiveData() {
      const userUuid = this.user?.uuid

      if (userUuid) {
        this.userStore.setSensitiveDataVisible(true, userUuid).then(() => {
          this.userStore.getUser({
            user: userUuid,
            agentReason: localStorage.getItem('agentReason') ?? '',
            hideRingNames: [],
          })
        })
      }
    }

    public confirmSensitiveData() {
      this.showSensitiveData()
      if (this.disableOneWeek) {
        const endDateString = this.getDateString(this.getTime(Date.now(), 1), 7)

        localStorage.setItem('confirmDialog1WeekDelay', endDateString)
      }

      this.sensitiveDataDialog = false
    }

    public sendAppActionMessage() {
      if (this.remoteAppAction === 'message') {
        this.remoteStore.sendRemoteMessage(this.user.uuid, this.remoteAppActionTitle, this.remoteAppActionText)
      } else {
        this.remoteStore.sendRemoteCommand(
          this.user.uuid,
          this.remoteAppAction + (this.remoteAppActionConfirm ? '?silent=true' : ''),
          this.remoteAppActionConfirm ? this.remoteAppActionText : undefined,
        )
      }

      this.remoteAppActionDialog = false
    }

    public async sendVerificationEmail() {
      const confirm = await this.$confirm(
        'Re-send the verification email?',
        `This will send a new verification email to <b>{{ user.email }}</b>`,
      )

      if (confirm) {
        this.userStore.sendVerificationEmail(this.user.uuid)
      }
    }

    public openSensitiveDataDialog() {
      const delay = localStorage.getItem('confirmDialog1WeekDelay')
      const currentDateString = this.getDateString(this.getTime(Date.now(), 1))

      if (delay && Date.parse(delay) >= Date.parse(currentDateString)) {
        this.showSensitiveData()
      } else {
        this.disableOneWeek = false
        this.sensitiveDataDialog = true
      }
    }

    public toggleTimezoneSetting(permanent: boolean) {
      let timezone = 'UTC'

      if (this.timezoneToggleAllowed) {
        timezone = this.prefsTimezoneSetting === 'UTC' ? 'USER' : 'UTC'
      }

      this.prefsStore.changeTimezoneSetting({
        timezone: timezone,
        permanent: permanent,
      })
    }

    private checkAccountDeletion() {
      this.deletionAlert = ''

      if (
        this.user?.deleteTicket?.status &&
        [deleteStatus.INITIAL, deleteStatus.PENDING, deleteStatus.REQUESTED].includes(this.user?.deleteTicket?.status)
      ) {
        const createAtFormat = this.formatDateTime(this.user.deleteTicket.created_at, 'HH:mm - D MMM YYYY')
        const updateAtFormat = this.formatDateTime(this.user.deleteTicket.created_at, 'HH:mm - D MMM YYYY')

        const createdAtAfter2Weeks = this.formatDateTime(
          this.$dayjs(this.user.deleteTicket.created_at).add(14, 'day'),
          'HH:mm - D MMM YYYY',
        )

        // Possible values for initiation_type are at least: darwin, app and command-line
        let initiationType = this.user.deleteTicket.initiation_type || ''

        initiationType = initiationType === 'darwin' ? 'Darwin' : initiationType
        initiationType = initiationType?.replace('-', ' ')

        if (this.user.deleteTicket.status === deleteStatus.PENDING) {
          this.deletionAlert = `Member has confirmed deletion request on ${createAtFormat}, account will be deleted in two weeks from confirmation on ${createdAtAfter2Weeks}`
        } else if (this.user.deleteTicket.status === deleteStatus.REQUESTED) {
          this.deletionAlert = `Account is waiting for deletion, ${initiationType} requested deletion on ${createAtFormat}`
        } else {
          this.deletionAlert = `Account is marked for deletion, member has NOT confirmed the deletion, request was sent on ${updateAtFormat}`
        }
      }
    }
  }

  export default toNative(AccountActions)
</script>
<style lang="scss" scoped>
  .highlight-alert {
    border: 1px solid #ff0000;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
  }

  .alert-message {
    text-transform: uppercase;
    font-size: 18px;
  }
</style>

import { formatDateTime } from '#utils/datetime/format'

import { ClientInfoData, LatestVersions, Nullable, Ring, RingDetails } from '#types'

function getOsDisplayName(os: string | null): string {
  if (os === 'ios') {
    return 'iOS'
  } else if (os === 'android') {
    return 'Android'
  } else {
    return 'Undefined'
  }
}

export function extractClientInfoFromRing(
  ringDetails: RingDetails,
  ring: Ring,
  versions: LatestVersions | null,
  memberDeviceOsVersion: Nullable<string>,
  memberClientVersion: Nullable<string>,
): ClientInfoData {
  const hardwareType: string = ring?.hardwareType || 'Unknown'

  const bootloaderVersion: string = ringDetails?.bootloaderUpdatedOn
    ? `${ring.bootloaderVersion} (${formatDateTime(ringDetails.bootloaderUpdatedOn, 'DD MMM YYYY', 0)} UTC)`
    : ring.bootloaderVersion
  const isLatestBootloaderVersion = versions?.bootloaderVersion === ring.bootloaderVersion
  const bootloaderVersionTooltip: string = isLatestBootloaderVersion
    ? 'Has newest version'
    : `Not the newest version.${versions?.bootloaderVersion ? ' (Latest: ' + versions?.bootloaderVersion + ')' : ''}`

  const firmwareVersion: string = ringDetails?.firmwareUpdatedOn
    ? `${ring.firmwareVersion} (${formatDateTime(ringDetails.firmwareUpdatedOn, 'DD MMM YYYY', 0)} UTC)`
    : ring.firmwareVersion
  const isLatestFirmwareVersion = versions?.firmwareVersion === ring.firmwareVersion
  const firmwareVersionTooltip: string = isLatestFirmwareVersion
    ? 'Has newest version'
    : `Not the newest version.${versions?.firmwareVersion ? ' (Latest: ' + versions?.firmwareVersion + ')' : ''}`

  const deviceModel = ring?.deviceModel ? ring.deviceModel[0].toUpperCase() + ring.deviceModel.slice(1) : 'Device'

  const clientApp = `${ring?.clientFlavor ? ring.clientFlavor : 'unknown'} app`

  const deviceOsVersionLabel = getOsDisplayName(ring.deviceOs) + ' version'
  const deviceOsVersion = ringDetails?.deviceOsVersionUpdatedOn
    ? `${ring.deviceOsVersion} (${formatDateTime(ringDetails.deviceOsVersionUpdatedOn, 'DD MMM YYYY', 0)} UTC)`
    : ring.deviceOsVersion

  let isSupportedOsVersion = false
  if (versions?.minimumOsVersion && memberDeviceOsVersion) {
    isSupportedOsVersion = versions?.minimumOsVersion <= memberDeviceOsVersion
  }

  const deviceOsVersionTooltip = isSupportedOsVersion
    ? 'Has supported OS version.'
    : `OS version not supported.${versions?.minimumOsVersion ? ' (Minimum: ' + versions?.minimumOsVersion + ')' : ''}`

  const isLatestClientAppVersion = !!versions?.appVersion && versions?.appVersion === memberClientVersion
  const clientAppVersion = ringDetails?.clientUpdatedOn
    ? `${ring.clientVersion} (${formatDateTime(ringDetails.clientUpdatedOn, 'DD MMM YYYY', 0)} UTC)`
    : ring.clientVersion
  const clientAppVersionTooltip = isLatestClientAppVersion
    ? 'Has newest version.'
    : `Not the newest version.${versions?.appVersion ? ' (Latest: ' + versions?.appVersion + ')' : ''}`

  const copyContents = `Ring: ${hardwareType}\nBootloader version: ${bootloaderVersion}\nFirmware version: ${firmwareVersion}\nDevice model: ${deviceModel} (${clientApp})\n${deviceOsVersionLabel}: ${deviceOsVersion}\nApp version: ${clientAppVersion}`
  return {
    hardwareType: hardwareType,
    isLatestBootloaderVersion: isLatestBootloaderVersion,
    bootloaderVersion: bootloaderVersion,
    bootloaderVersionTooltip: bootloaderVersionTooltip,
    isLatestFirmwareVersion: isLatestFirmwareVersion,
    firmwareVersion: firmwareVersion,
    firmwareVersionTooltip: firmwareVersionTooltip,
    deviceModel: deviceModel,
    clientApp: clientApp,
    deviceOsVersionLabel: deviceOsVersionLabel,
    deviceOsVersion: deviceOsVersion ?? 'Unknown',
    isSupportedOsVersion: isSupportedOsVersion,
    deviceOsVersionTooltip: deviceOsVersionTooltip,
    isLatestClientAppVersion: isLatestClientAppVersion,
    clientAppVersion: clientAppVersion ?? 'Unknown',
    clientAppVersionTooltip: clientAppVersionTooltip,
    copyContents: copyContents,
  }
}

/**
 * These are all API responses related to outo api. These should not be used in the
 * application but converted to internal format using extract functions.
 */
import { Nullable, SerialDetails, ShippingDetailsInfo, TeamsOrganizationType, TeamsPermission } from '#types'

/*
 * User related data models
 */

interface MemberFlagsResponse {
  usingExperimentalApp: boolean
  queuedForDeletion: boolean
  markedForDeletion: boolean
  userRequestedDeletion: boolean
}

export interface UserCloudManagementResponse {
  /**
   * Member's user ID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  uuid: string

  /**
   * Member's analytics ID, for example 9e8675b9-8ddc-4ec1-95da-ff5246ec2fd0
   */
  analyticsId: string | null
  // similar to registrationDate but with timestamp including example: 2023-05-12T10:02:44.770794+00:00
  userCreatedAt: string | null

  /**
   * Date, for example 2021-05-12
   */
  registrationDate: string | null

  /**
   * Email address, for example foobar@example.com
   *
   * Null if user doesn't have (personal data) access to see member's email
   */
  email: string | null
  emailVerified: boolean

  /**
   * Integer, for example 2
   */
  ringCount: number
  environment: string | null

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  emailVerificationBypassed: boolean

  /**
   * Integer, for example 3
   */
  timeZone: number | null

  /**
   * This is probably not present in cloud management user response
   */
  flags: MemberFlagsResponse

  /**
   * HIPAA status, True if user has HIPAA association, False if not
   */
  hipaaStatus: boolean

  /**
   * Essence id, null if member is not associated with essence
   */
  essenceId: string | null

  countryOfResidence: string | null
}

export interface UserAccessResponse {
  ownerAccess: boolean
  supportAccess: boolean
  sharedAccess: boolean
}

export interface UserRingDetailsResponse {
  bootloaderUpdatedOn: string | null
  bootloaderVersion: string
  offeredBootloaderVersion: string | null
  chargerSerialNumber: string | null
  clientFlavor: string
  clientVersion: string
  clientUpdatedOn: string
  color: string | null
  design: string | null
  deviceModel: string
  deviceOs: string
  deviceOsVersion: string
  deviceOsUpdatedOn: string
  firmwareUpdatedOn: string | null
  firmwareVersion: string
  offeredFirmwareVersion: string | null
  hardwareType: string
  hardwareVersion: string
  macAddress: string
  netsuiteSalesOrderId: string | null
  orderStatus: string | null
  orderTimestamp: string | null
  returnReason: string | null
  returnStatus: string | null
  ringInfoModifiedAt: string
  ringSerialNumber: string
  serialNumber: string
  warrantyStartDate: string | null
  warrantyEndDate: string | null
  zendeskNotes: string[] | null
  registrationDate: string
  ringSerialInfo: SerialDetails | null
  chargerSerialInfo: SerialDetails | null
  lastSyncedAt: string | null
  fulfillmentTimestamp?: string | null
  returnEligibility: ReturnEligibilityResponse
}

export interface UserRingsResponse {
  rings: UserRingDetailsResponse[] | []
}

export interface ReturnEligibilityResponse {
  thresholdDays: number | null
  eligibleForReturn: boolean | null
  daysSinceFulfillment: number | null
}

export interface UserEmailResponse {
  /**
   * Email address, for example foobar@example.com
   *
   * Null if user doesn't have (personal data) access to see member's email
   */
  email: string | null
  primary: boolean
  verified: boolean

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  createdAt: string
  deleted: boolean

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  deletedAt: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  verifiedAt: string | null
}

export interface UserEmailEventsResponse {
  /**
   * For example "fwpwd_web", "pwreset"
   */
  eventType: string

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  occurredAt: string

  /**
   * For example: { "email": "foobar@example.com" }
   */
  json: any | null
}

export interface UserEmailsResponse {
  emails: UserEmailResponse[]
  events: UserEmailEventsResponse[]
}

export interface UserDeletionTicketSingleResponse {
  /**
   * For example "all"
   */
  aspects: string[]

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  createdAt: string

  /**
   * Email address, for example foobar@example.com
   */
  initiatingAdminEmail: string | null

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f or ''
   */
  initiatingAdminUid: string | null

  /**
   * For example "app"
   */
  initiationType: string | null

  /**
   * For example "requested"
   */
  status: string

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  uid: string

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  updatedAt: string

  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  userUid: string
}

export interface UserDeletionTicketsResponse {
  tickets: UserDeletionTicketSingleResponse[]
}

export interface UserZendeskTicketResponse {
  previous: null | string
  next: null | string
  count: number
  contents: UserZendeskTicketContents[]
}

export interface UserZendeskTicketContents {
  ticketId: string
  state: string
  open_timestamp: string
  closed_timestamp: null | string
  updated_at: null | string
  user_uid: string
  netsuiteRaTranid: null | string
  notes: null | string
}

export interface MacroArrayInfo {
  availabilityType: string
  description: string
  id: number
  title: string
}

export interface AlertsItemsInfo {
  name: string
  result: string
  message: string
}

export interface AlertsInfo {
  id: string
  type: string
  tooltip: { headers: any[]; items: AlertsItemsInfo[] }
  color: null | string
  message: null | string
  link: string
  macro: {
    title: string
    id: string | number
  }
}

/*
 * Rings related data models
 */

export interface MarkReturnedResponse {
  itemsReturned: number
}

export interface MarkReturnedRequest {
  transactionId: string
  components: { type: string; serialNumber?: string }[]
  factoryReset?: { status: string; reason: string }
  handlerEmail: string
}

export interface RingSearchRequest {
  /**
   * Return rings that match this string. Use searchType to control what field this is matched against
   */
  search: string

  /**
   * Match ring and charger serials partially from the start of the string.
   */
  partialSearch?: boolean

  /**
   * Cursor to page
   * @example >dt:2023-06-29+11:53:38.933480
   */
  page?: string

  /**
   * Number of results to return. Defaults to 100.
   *
   * @example 150
   */
  pageSize?: number

  /**
   * Filter possible rings to different warranty states. Has no effect when searching for Return Authorizations.
   */
  searchFilter?: 'warrantyPending' | 'warrantyReceived' | 'warrantyClosed'

  /**
   *  Match the search query to a field in a specific way
   *  ringSerial:
   *    only match if the ring serial is an exact match
   *  chargerSerial:
   *    only match if the charger serial is an exact match
   *  netsuiteOrderId:
   *    only match if the netsuite order id is an exact match (these start with SO-)
   *  netsuiteReturnId:
   *    only match if the netsuite order id is an exact match (these start with RA-)
   */
  searchType?: 'ringSerial' | 'chargerSerial' | 'netsuiteOrderId' | 'netsuiteReturnId'
}

export interface RingDetailsResponse {
  ring: RingDetailsRingResponse
  users: RingDetailsUserResponse[]
  devices: RingDetailsMemberDeviceResponse[]
  clients: RingDetailsMemberClientResponse[]
}

export interface RingDetailsRingResponse {
  macAddress: string
  hardwareType: string
  firmwareVersion: string
  hardwareVersion: string
  bootloaderVersion: string
  serialNumber: string
  bootloaderUpdatedOn: string | null
  firmwareUpdatedOn: string | null
  registrationDate: string | null
  lastSeenDate: string
  design: string | null
  color: string | null
  ringSerialInfo: RingDetailsSerialResponse | null
}

export interface RingDetailsSerialResponse {
  product: string | null
  factory: string | null
  hwMinor: string | null
  hwMajor: string | null
  size: number | null
  manufacturingWeek: number | null
  manufacturingYear: number | null
  generation: string | null
}

export interface RingDetailsUserResponse {
  userUuid: string
  firstSeen: string
  lastSeen: string
}

export interface RingDetailsMemberDeviceResponse {
  /**
   * UUID, for example f5466b55-741-4c41-aafc-901ca03d358f
   */
  id: string | null

  /**
   * For example iPhone15,2
   */
  model: string

  /**
   * For example: "ios" or "android"
   */
  os: string

  /**
   * For example "16.4.1"
   */
  osVersion: string

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  osVersionUpdatedOn: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  firstSeen: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  lastSeen: string | null

  /**
   * For example "debug", "release"
   */
  clientFlavor: string

  /**
   * Integer, for example 2
   */
  registrationCount: number | null
}

export interface RingDetailsMemberClientResponse {
  /**
   * For example: "ios" or "android"
   */
  platform: string

  /**
   * Integer, for example 2
   */
  registrationCount: number | null

  /**
   * For example "debug", "release"
   */
  flavor: string

  /**
   * For example "en", "fi"
   */
  language: string | null

  /**
   * For example "4.10.1 (2303151455)"
   */
  version: string

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  updatedOn: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  firstSeen: string | null

  /**
   * For example "2023-04-18T10:02:44.770794+00:00"
   */
  lastSeen: string | null
}

export interface WarrantyDetailsResponse {
  ringset: {
    ringSerialNumber: Nullable<string>
    chargerSerialNumber: Nullable<string>
    warrantyStartDate: Nullable<string>
    warrantyEndDate: Nullable<string>
    escStartDate: Nullable<string>
    escEndDate: Nullable<string>
    ringSerialInfo: SerialDetails | null
    chargerSerialInfo: SerialDetails | null
    itemCode: Nullable<string>
    itemDescription: Nullable<string>
  }
  order: {
    orderId: Nullable<number>
    transactionId?: Nullable<string>
    timestamp: Nullable<string>
    lastModified?: Nullable<string>
    retailPartner: Nullable<string>
    businessLine: Nullable<string>
    status: Nullable<string>
  } | null
  fulfillment?: {
    fulfillmentId: number
    transactionId?: Nullable<string>
    status: Nullable<string>
    timestamp: Nullable<string>
    lastModified?: Nullable<string>
  } | null
  ringReturn: DeviceReturnInfoResponse
  chargerReturn: DeviceReturnInfoResponse
}

export interface DeviceReturnInfoResponse {
  returnAuthorization: Nullable<ReturnAuthorizationInfoResponse>
  replacedBy: Nullable<string>
  replaces: Nullable<string>
}

export interface ReturnAuthorizationInfoResponse {
  returnId: number
  transactionId: string
  timestamp: string
  lastModified: string
  status: string
  type: string
  reason: string
  resolution: string
  returnedItems: Nullable<ReturnItemResponse[]>
}

export interface ReturnItemResponse {
  uid: string
  itemType: string
  serialNumber: Nullable<string>
  status?: string
  handlerEmail?: string
  factoryReset?: null | {
    status: string
    reason: string
  }
  returnNumber: number
  markedToNetsuiteAt: Nullable<string>
  sku: Nullable<string>
  createdAt: string
}

/*
 * Teams related data models
 */

export type CreateOrganizationRequest = {
  /**
   * Name of the organization
   *
   * @minLength 3
   */
  name: string

  /**
   * Type of the organization
   *
   * example: 'coach'
   */
  type: TeamsOrganizationType
  permissions?: TeamsPermission[]
  admin_email: string
  account_manager_email: string | null

  /**
   * Email template version
   *
   * @minLength 1
   *
   * example: 'V2'
   */
  email_template_version?: string

  /**
   * External ID of the organization
   *
   * @minLength 1
   */
  external_id?: string | null

  /**
   * Subscription start date
   *
   * example: '2023-08-14T00:00:00+00:00'
   */
  subscription_valid_from?: string | null
}

/*
 * Orders related data models
 */

// Temporary interface so we can display raw order information on our PoC UI.
export interface OrderRaw {
  object: any
  json: string
  jsonPretty: string
}

export interface LatestVersionsResponse {
  firmwareVersion: Nullable<string>
  bootloaderVersion: Nullable<string>
  minimumOsVersion: Nullable<string>
  appVersion: Nullable<string>
}

/*
 * Subscription related data models
 */

/**
 * This file contains all API responses related to subscription store. These should not be used in the
 * application but converted to internal format using extract functions.
 */
export interface PaymentMethodsContactResponse {
  address1: string
  address2?: string
  city: string
  country: string
  firstName: string
  lastName: string
  personalEmail: string
  postalCode: string
  state?: string
  telephone?: string
}

export interface ContactDetailsResponse {
  billToContact: PaymentMethodsContactResponse | null
  shipToContact: PaymentMethodsContactResponse | null
}

export interface PaymentMethodBaseResponse {
  id: string
  type: string
  isDefault: boolean
  status: string
  email?: string
  createdAt: string
}

export interface PaymentMethodCreditCardResponse extends PaymentMethodBaseResponse {
  cardType: string
  cardNumber: string
  expirationMonth: number
  expirationYear: number
  bankIdentificationNumber: string
}

type PaymentMethodResponse = PaymentMethodBaseResponse | PaymentMethodCreditCardResponse

export interface PaymentMethodsResponse {
  paymentMethods: PaymentMethodResponse[]
}

export interface AddressData {
  userId: string
  shippingDetails: ShippingDetailsInfo
  validateAddress?: boolean
}

export interface SubscriptionActionParams {
  validateAddress: Nullable<boolean>
}

export interface UpgradeSubscriptionActionData {
  userId: string
  subscriptionId: string
  shippingDetails?: ShippingDetailsInfo
  validateAddress?: boolean
}

export interface CompensateSubscriptionBaseRequest {
  compensationMonths: number
  reason: string
  issueStartDate: string
}

export interface CompensateSubscriptionWithEscalationReasonRequest extends CompensateSubscriptionBaseRequest {
  escalationReason: string
}

/*
 * Diagnostics related data models
 */

export enum DiagnosticErrorLevel {
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
  NotEnoughData = 'not_enough_data',
}

export interface RingHardwareInfoResponse {
  hardwareType: string | null
  color: string | null
  firmwareVersion: string
  size: number | null
  lastSyncEpoch: number | null
  lastSyncDate: string | null
}

export interface AvailableDiagnosticResponseItem {
  name: string
  category: string
  versions: number[]
}

export interface DiagnosticResponse {
  message: string
  title: string
  status: DiagnosticErrorLevel
  guruCardUrl: string | null
  extraInfo: {
    headers: { title: string; key: string }[]
    items: object[]
  }
  show: {
    darwin: boolean
    darwinSidecar: boolean
  }
  isPriority: boolean
}

/*
 * Summary related data models
 */
export interface SummaryDataSingleResponse {
  /**
   * Day in YYYY-MM-DD format
   *
   * @example 2024-05-20
   */
  day: string

  /**
   * Member's timezone in minutes from UTC at the end of the day. Null until then.
   *
   * For example 180 is '+03:00', -480 is '-08:00' and null/0 is UTC
   *
   * @example 180
   */
  timezone: Nullable<number>

  ringSerialNumber: Nullable<string>

  /**
   * Bedtime start, full timestamp with timezone
   *
   * @example "2024-09-04T22:02:44.770794+03:00"
   */
  bedtimeStart: Nullable<string>

  /**
   * Bedtime end, full timestamp with timezone
   *
   * @example "2024-09-04T08:15:10.450131+03:00"
   */
  bedtimeEnd: Nullable<string>
  lowestHeartRate: Nullable<number>
  highestTemperature: Nullable<number>
  nightlyIbiQuality: Nullable<number>
  sleepScore: Nullable<number>
  readinessScore: Nullable<number>
  equivalentWalkingDistance: Nullable<number>
  nonWearTime: Nullable<number>
  nonWearTimeDuringBedtime: Nullable<number>
  nonWearPeriods: Nullable<number>
  dhrIbiQuality: Nullable<string>
  selfTestSuccess: Nullable<boolean>
  selfTestDetails: Nullable<string[]>
  fieldTestSuccess: Nullable<boolean>
  fieldTestDetails: Nullable<string[]>
  events: Nullable<string[]>
}

export interface SummaryDataResponse {
  summaryData: SummaryDataSingleResponse[]
}

export interface SleepPeriodSingleResponse {
  day: string
  bedtimeStart: string
  bedtimeEnd: string
  durationSeconds: number

  /**
   * Highest temperature during sleep in degrees Celcius.
   * @example 37.5
   */
  highestTemperature: number
  ibiQualityPercentage: number
  lowBatteryAlert: boolean // Flag indicating if a low battery alert occurred.
  lowestHeartRate: number

  /**
   * Sleep score
   * @min 0
   * @max 100
   */
  score: number

  /**
   * Sleep type
   * @example long_sleep, deep_sleep, rem_sleep
   */
  type: Nullable<string>
}

export interface SleepPeriodsResponse {
  sleepPeriods: SleepPeriodSingleResponse[]
}

export interface TeamsOrganizationSingleResponse {
  name: string
  uid: string
  type: string
  createdAt: string
  memberCount: number
  coachCount: number
  groupCount: number
  accountManagerEmail?: string | null
  externalId?: string | null
  planName: string
  subscriptionStatus?: string
  validTo?: Nullable<string>
}

export interface TeamsOrganizationAdminResponse {
  email: string
  userUid: string
}

export interface TeamsOrganizationDetailsResponse extends TeamsOrganizationSingleResponse {
  admins: TeamsOrganizationAdminResponse[]
  permissions: string[]
}

export interface TeamsOrganizationsResponse {
  teams: TeamsOrganizationSingleResponse[]
}

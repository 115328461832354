import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { Diagnostics } from '#types'

const REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS = 'getSignupFlowDiagnostics'
const REQUEST_SOURCE_PREPAID_DIAGNOSTICS = 'getPrepaidDiagnostics'

@Store()
export class DiagnosticsStore extends BaseStore {
  public dataWait = false

  public signupFlowDiagnostics: Diagnostics | null = null
  public prepaidDiagnostics: Diagnostics | null = null

  public get signupFlowDiagnosticsRequestError(): string {
    return this.getDiagnosticsRequestError(REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS)
  }

  public get prepaidDiagnosticsRequestError(): string {
    return this.getDiagnosticsRequestError(REQUEST_SOURCE_PREPAID_DIAGNOSTICS)
  }

  public getDiagnosticsRequestError(requestSource: string): string {
    const error = this.getRequestError(requestSource)
    if (error) {
      return error.userMessage ?? 'Something went wrong. Please try again.'
    }
    return ''
  }

  public async getSignupFlowDiagnostics(uuid: string) {
    this.signupFlowDiagnostics = null
    this.dataWait = true
    this.resetRequestError(REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS)
    this.updateDataWait({ source: REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS, wait: true })

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/signup-flow` },
      REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS,
    )
      .then((value: AxiosResponse | null) => {
        this.signupFlowDiagnostics = value?.data
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS)
      })

    this.dataWait = false
  }

  public async getPrepaidDiagnostics(uuid: string, ring_serial: string) {
    this.prepaidDiagnostics = null
    this.dataWait = true
    this.resetRequestError(REQUEST_SOURCE_PREPAID_DIAGNOSTICS)
    this.updateDataWait({ source: REQUEST_SOURCE_PREPAID_DIAGNOSTICS, wait: true })

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/rings/${ring_serial}/diagnostics/prepaid` },
      REQUEST_SOURCE_PREPAID_DIAGNOSTICS,
    )
      .then((value: AxiosResponse | null) => {
        this.prepaidDiagnostics = value?.data
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_SIGNUP_FLOW_DIAGNOSTICS)
      })

    this.dataWait = false
  }
}

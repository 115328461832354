<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <DiagnosticAlert
    v-if="diagnostic"
    v-model:extra-info="active"
    :diagnostic-id="diagnostic.diagnosticId"
    :index="diagnostic.diagnosticId"
    :alert="diagnostic"
    :sidecar="sidecar"
    :pinned="isPinned(diagnostic.diagnosticId)"
    :show-hidden-diagnostics="showHiddenDiagnostics"
    :single="single"
    @toggle-pin="togglePin"
  />
  <v-expand-transition>
    <div v-show="active === diagnostic.diagnosticId && diagnostic.extraInfo.items.length">
      <v-data-table
        :headers="diagnostic?.extraInfo?.headers"
        :items="diagnostic?.extraInfo?.items"
        :items-per-page="diagnosticsDetailsPerPage"
        disable-sort
        class="elevation-1"
      >
        <template #item="{ index, item }">
          <tr>
            <td v-for="(th, thIndex) in diagnostic?.extraInfo?.headers" :key="th.key">
              <div v-if="th.key === 'message'">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="formatMessage(item[th.key])" />
              </div>
              <div v-else>
                {{ item[th.key] }}
                <v-tooltip v-if="item.guruCardUrl && isLastTableColumn(thIndex)" location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      color="blue"
                      variant="text"
                      density="compact"
                      size="small"
                      :href="item.guruCardUrl"
                      target="_blank"
                      class="float-right"
                    >
                      <span v-if="!sidecar">GURU Card</span>
                      <span v-else>
                        <v-icon v-bind="props" size="large">mdi-alpha-g-box-outline</v-icon>
                      </span>
                    </v-btn>
                  </template>
                  {{ item.guruCardUrl ? item.guruCardUrl : 'Guru card' }}
                </v-tooltip>
              </div>
              <div
                v-if="item.details && isLastTableColumn(thIndex)"
                small
                class="mb-1 details-button"
                @click="toggleDetails(index)"
              >
                Details
              </div>
            </td>
          </tr>
          <tr v-if="item.details && index === detailsExpand">
            <td colspan="3">
              <p class="mt-3">
                <strong>Message:</strong>
                {{ item.details.title }}
              </p>
              <v-data-table
                :headers="item.details.headers"
                :items="item.details.items"
                :items-per-page="diagnosticsSubDetailsPerPage"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #[`item.date`]="{ item }">
                  <span>{{ formatDateTime((item as any).date, 'Do MMM YYYY') }}</span>
                </template>
              </v-data-table>
            </td>
          </tr>
        </template>
        <!--TODO: change back to hide-default-footer if Vuetify3 update this-->
        <template v-if="sidecar || diagnostic?.extraInfo?.items?.length < diagnosticsDetailsPerPage" #bottom />
      </v-data-table>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { maxPinnedDiagnosticsCount } from '#views/members/constants'

  import { DiagnosticsStoreV3 } from '#stores'

  import { Diagnostic, Nullable } from '#types'

  @Component
  class DiagnosticInfos extends mixins(DateTime) {
    @Prop() public diagnostic!: Diagnostic
    @Prop() public sidecar!: boolean
    @Prop() public showHiddenDiagnostics!: boolean
    // The 'single' prop is used for single diagnostics and will have a different style than the Diagnostics (example in OuraSubscription).
    @Prop() public single!: boolean

    public active: Nullable<string> = null
    public diagnosticsStore = new DiagnosticsStoreV3()

    /**
     * How many diagnostics details to show per page
     *
     * Each diagnostics items can have details. For example feature diagnostics has list of reasons why it failed
     * (too much movement, in charger, not in finger etc).
     */
    public diagnosticsDetailsPerPage = 10

    /**
     * How many diagnostics sub details to show per page
     *
     * Each diagnostics details can have also sub details, for example resilience diagnostics has details
     * "historical data" which has sub details - list of all the dates as when analytics passed or failed.
     */
    public diagnosticsSubDetailsPerPage = 5

    public detailsExpand: number | null = null

    /**
     *
     * This checks whether the table row data is in the last column to determine whether to add the extra text, such as "Details" or a "Guru" link.
     */
    public isLastTableColumn(thIndex: number): boolean {
      return thIndex + 1 === this.diagnostic?.extraInfo?.headers.length
    }

    public formatMessage(message: string) {
      return message.replace(/\n/g, '<br>')
    }

    public toggleDetails(index: number) {
      this.detailsExpand = index === this.detailsExpand ? null : index
    }

    public isPinned(diagnosticId: string): boolean {
      return this.diagnosticsStore.isPinned(diagnosticId)
    }

    public togglePin(diagnosticId: string) {
      this.diagnosticsStore.togglePin(diagnosticId, maxPinnedDiagnosticsCount)
    }
  }

  export default toNative(DiagnosticInfos)
</script>

<style lang="scss" scoped>
  :deep(.details-button) {
    color: #2196f3;
    font-size: 0.9rem;
    cursor: pointer;
  }
</style>

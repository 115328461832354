<template>
  <v-dialog width="800" min-height="400" :model-value="open" @after-leave="close()">
    <v-card :loading="actionPending">
      <v-card-title>Mark devices as returned</v-card-title>

      <v-card-text class="mb-n8">
        <div>
          <v-checkbox
            v-model="userChoices.returnRing"
            hide-details="auto"
            label="Mark ring as returned"
            class="shrink mr-0 mt-0 ma-0 pa-0"
            :disabled="actionPending || !returnDialogData?.ring.allowReturn"
          />

          <v-tooltip v-if="returnDialogData?.ring.tooltipText" activator="parent" location="top" :disabled="false">
            {{ returnDialogData?.ring.tooltipText }}
          </v-tooltip>
        </div>

        <div>
          <v-checkbox
            v-model="userChoices.returnCharger"
            hide-details="auto"
            label="Mark charger as returned"
            class="shrink mr-0 mt-0 ma-0 pa-0"
            :disabled="actionPending || !returnDialogData?.charger.allowReturn"
          />

          <v-tooltip v-if="returnDialogData?.charger.tooltipText" activator="parent" location="top" :disabled="false">
            {{ returnDialogData?.charger.tooltipText }}
          </v-tooltip>
        </div>

        <v-text-field
          v-if="userChoices.returnCharger"
          v-model.trim="userChoices.confirmChargerSerial"
          class="ml-6 mt-n2 mb-4"
          label="Enter charger serial number"
        />

        <v-checkbox
          v-model="userChoices.returnCable"
          hide-details="auto"
          class="shrink mr-0 mt-0 ma-0 pa-0"
          label="Mark charger cable as returned"
          :disabled="actionPending || !returnDialogData?.cable.allowReturn"
        />

        <div v-if="returnDialogData?.ring.allowReturn">
          <div class="headline ml-0">Ring factory reset status:</div>

          <v-radio-group v-model="userChoices.resetStatus">
            <v-radio
              :disabled="actionPending || !userChoices.returnRing"
              label="Ring was factory reset by agent at the office"
              value="true"
            />
            <v-radio
              :disabled="actionPending || !userChoices.returnRing"
              label="Ring could not be factory reset"
              value="false"
            />
            <v-radio
              :disabled="actionPending || !userChoices.returnRing"
              label="Ring was not factory reset"
              value="none"
            />
          </v-radio-group>
        </div>

        <v-text-field
          v-if="userChoices.resetStatus === 'none'"
          v-model="userChoices.reasonForNoReset"
          label="Reason"
          class="ml-3 mt-n6 mb-2"
          :disabled="actionPending"
        />

        <v-alert v-if="!!extractError" type="error" color="red" class="my-4">
          {{ extractError }}
        </v-alert>
        <v-alert v-if="!!errorMarkReturned" type="error" color="red" class="my-4">
          {{ errorMarkReturned }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn text="Cancel" variant="text" class="mr-2" :disabled="actionPending" @click.stop="close()" />
        <v-btn
          text="Confirm"
          variant="text"
          color="primary"
          :disabled="!confirmButtonEnabled"
          @click="markAsReturned()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { extractMarkReturnedRequests } from '#utils/mark-returned/extract'

  import { AppStore, RingsStore } from '#stores'

  import { Nullable, ReturnDialogData, ReturnDialogUserChoices, WarrantyData } from '#types'

  @Component({})
  class MarkItemReturned extends Vue {
    @Prop() public open!: boolean

    @Prop() public warrantyData!: WarrantyData | null
    @Prop() public returnDialogData!: ReturnDialogData | null

    @Prop() public refreshDeviceSerial!: string

    public actionPending = false

    public extractError: Nullable<string> = null

    public userChoices: ReturnDialogUserChoices = {
      returnRing: false,
      returnCharger: false,
      returnCable: false,
      resetStatus: 'true',
      reasonForNoReset: '',
      confirmChargerSerial: null,
    }

    protected readonly appStore = new AppStore()
    protected readonly ringsStore = new RingsStore()

    public get errorMarkReturned() {
      return this.ringsStore.errorMarkReturned
    }

    public get confirmButtonEnabled() {
      if (this.actionPending) {
        return false
      }

      if (!this.userChoices.returnRing && !this.userChoices.returnCharger && !this.userChoices.returnCable) {
        return false
      }

      if (
        this.userChoices.returnCharger &&
        this.userChoices.confirmChargerSerial !== this.returnDialogData?.charger.serial
      ) {
        return false
      }

      return true
    }

    @Emit('close')
    public close() {
      this.ringsStore.errorMarkReturned = null

      this.extractError = null

      return null
    }

    @Emit('update')
    public update() {
      return true
    }

    public async markAsReturned() {
      let allRequestsOk = true

      this.actionPending = true

      if (this.returnDialogData) {
        const userEmail = this.appStore.user?.email

        try {
          const requests = extractMarkReturnedRequests(this.userChoices, this.returnDialogData, userEmail)

          for (const request of requests) {
            const status = await this.ringsStore.markDeviceReturned({ request: request })

            if (!status) {
              // if any (first) "mark as returned" request fails, we don't want to make consecutive requests
              allRequestsOk = false
              break
            }
          }
        } catch (e: unknown) {
          if (e instanceof Error) {
            this.extractError = e.toString()
          }

          this.actionPending = false

          return
        }
      }

      this.actionPending = false

      /**
       * What will happen here is that if some "mark as returned" requests succeed but some fail, we don't
       * refresh the data (because the whole UI flickers annoyingly) so user might see stale data.
       * We should figure out a better way to handle this kind of scenario. We should probably also communicate to
       * user that their actions partially succeeded, and what actually happened. Now we just show generic error
       * message which doesn't really tell what state the data is in.
       *
       * Also when all requests succeed, we just simply close the dialog and refresh the data, but don't tell
       * user what actually happened.
       */
      if (allRequestsOk) {
        this.update()

        this.close()
      }
    }
  }

  export default toNative(MarkItemReturned)
</script>

<template>
  <v-card min-width="100%">
    <v-card-title>
      <p class="text-overline">Client info</p>
      <v-spacer />
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="grey"
            variant="plain"
            class="mr-n4"
            icon="mdi-content-copy"
            @click="copyContents()"
          />
        </template>

        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
      </v-tooltip>
    </v-card-title>

    <v-card-text class="px-10 pb-10 mt-3">
      <div>
        <v-row class="align-end">
          <v-col class="pr-6" cols="12" md="6" lg="6" xl="3">
            <div class="mx-n2 mt-n3 mb-6 text-no-wrap">Oura Ring ({{ clientInfo.hardwareType }})</div>

            <div class="text--black">
              <v-text-field
                label="Bootloader version"
                readonly
                :model-value="clientInfo.bootloaderVersion"
                :class="{
                  active: clientInfo.isLatestBootloaderVersion,
                }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon
                        icon="mdi-star"
                        :color="clientInfo.isLatestBootloaderVersion ? 'green' : ''"
                        v-bind="props"
                      />
                    </template>
                    {{ clientInfo.bootloaderVersionTooltip }}
                  </v-tooltip>
                </template>
              </v-text-field>

              <v-text-field
                label="Firmware version"
                readonly
                :model-value="clientInfo.firmwareVersion"
                :class="{ active: clientInfo.isLatestFirmwareVersion }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon
                        icon="mdi-star"
                        :color="clientInfo.isLatestFirmwareVersion ? 'green' : ''"
                        v-bind="props"
                      />
                    </template>
                    {{ clientInfo.firmwareVersionTooltip }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-col>

          <v-col class="pr-6" cols="12" md="6" lg="6" xl="3">
            <div class="mx-n2 mt-n3 mb-6 text-no-wrap">
              {{ clientInfo.deviceModel }}
              ({{ clientInfo.clientApp }})
            </div>

            <div class="text--black">
              <v-text-field
                :label="clientInfo.deviceOsVersionLabel"
                :model-value="clientInfo.deviceOsVersion"
                readonly
                :class="{ active: clientInfo.isSupportedOsVersion }"
              >
                <template #append-inner>
                  <v-tooltip location="top">
                    <template v-if="ring.deviceOs === 'android'" #activator="{ props }">
                      <v-btn v-bind="props" color="grey" icon="mdi-help-circle-outline" @click.stop="goToLink()" />
                    </template>
                    {{ 'Click to learn more: API level versions' }}
                  </v-tooltip>
                </template>
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon icon="mdi-star" :color="clientInfo.isSupportedOsVersion ? 'green' : ''" v-bind="props" />
                    </template>
                    {{ clientInfo.deviceOsVersionTooltip }}
                  </v-tooltip>
                </template>
              </v-text-field>

              <v-text-field
                label="App version"
                readonly
                :model-value="clientInfo.clientAppVersion"
                :class="{ active: clientInfo.isLatestClientAppVersion }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon
                        icon="mdi-star"
                        :color="clientInfo.isLatestClientAppVersion ? 'green' : ''"
                        v-bind="props"
                      />
                    </template>
                    {{ clientInfo.clientAppVersionTooltip }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { extractClientInfoFromRing } from '#utils/client-info/extract'

  import { ConfigStore, PrefsStore } from '#stores'

  import { ClientInfoData, LatestVersions, Member, Ring, RingDetails } from '#types'

  @Component
  /**
   * ClientInfo component for new (cloud management) user responses.
   */
  class ClientInfo extends Vue {
    @Prop() public member!: Member
    @Prop() public ring!: Ring
    @Prop() public ringDetails!: RingDetails

    public configStore = new ConfigStore()
    public prefsStore = new PrefsStore()

    public useClipboard = useClipboard()

    public get versions(): LatestVersions | null {
      return this.configStore.versions
    }

    public async mounted() {
      await this.configStore.getLatestVersions(this.member.uuid)
    }

    public goToLink() {
      window.open('https://en.wikipedia.org/wiki/Android_version_history', '_blank', 'noopener,noreferrer')
    }

    public get clientInfo(): ClientInfoData {
      return extractClientInfoFromRing(
        this.ringDetails,
        this.ring,
        this.versions,
        this.member.devices[0]?.osVersion ?? null,
        this.member.clients[0]?.version ?? null,
      )
    }

    public copyContents() {
      this.useClipboard.copy(this.clientInfo.copyContents)
    }
  }

  export default toNative(ClientInfo)
</script>

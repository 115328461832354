<template>
  <v-container class="px-0">
    <p class="text-overline mb-4 ml-2">{{ title }}</p>
    <div ref="fullScreenContainer" class="fullscreenContainer">
      <div ref="plotWrapper">
        <div ref="plotlygraph" :class="[isFullScreen ? 'plotlyFullscreen' : 'mt-n2 plotly']" />
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
  import Plotly from 'plotly.js-strict-dist'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { plotlyIconFullscreen } from '#views/members/constants'

  import { EMPTY_GRAPH } from '#utils/generic-graph/generic-graph'

  import { PrefsStore } from '#stores'

  import { GraphMode, PlotTypes, PlotlyFigure } from '#types'

  @Component
  class PlotlyView extends Vue {
    @Prop() public title!: string
    @Prop() public data!: PlotlyFigure
    @Prop() public plotlyConfig!: Plotly.Config
    @Prop() public fullScreenSupported: boolean = true
    @Prop({ default: false }) private autoscale!: boolean

    private PlotResizeObserver = new ResizeObserver(this.renderPlot)
    public isFullScreen: boolean = false
    public plotlyChart: any

    private prefsStore = new PrefsStore()

    declare public $refs: {
      plotWrapper: Element
      fullScreenContainer: Element
    }

    public get graphMode() {
      return this.prefsStore.graphMode
    }

    @Watch('data')
    private onDataChanged() {
      this.renderPlot()
    }

    @Watch('renderingMode')
    private onRenderingModeChanged() {
      this.renderPlot()
    }

    public mounted() {
      if (!this.data) {
        this.data = EMPTY_GRAPH
      }

      this.PlotResizeObserver.observe(this.$refs.plotWrapper)

      if (this.$refs.fullScreenContainer) {
        this.$refs.fullScreenContainer.addEventListener('fullscreenchange', () => {
          this.isFullScreen = !this.isFullScreen
        })
      }
    }

    public handleRenderingMode() {
      for (const graph of this.data.data) {
        if (graph.type === PlotTypes.Scatter && this.graphMode == GraphMode.WebGL) {
          graph.type = PlotTypes.ScatterGL
        } else if (graph.type === PlotTypes.ScatterGL && this.graphMode == GraphMode.SVG) {
          graph.type = PlotTypes.Scatter
        }
      }
    }

    private toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.fullScreenContainer.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }

    private buildConfig(): object {
      let config = {} as Plotly.Config
      if (this.plotlyConfig) {
        config = JSON.parse(JSON.stringify(this.plotlyConfig))
      }
      if (!this.plotlyConfig?.modeBarButtonsToAdd) {
        config.modeBarButtonsToAdd = []
      }

      const fullScreenButton: Plotly.ModeBarButtonAny = {
        name: 'toggleFullscreen',
        title: 'Toggle Fullscreen',
        icon: plotlyIconFullscreen,
        click: this.toggleFullscreen,
      }

      config.modeBarButtonsToAdd.push(fullScreenButton)

      return config
    }

    private renderPlot() {
      this.handleRenderingMode()
      const plotlyConfig = this.buildConfig()

      if ((this.$refs as any)?.plotlygraph) {
        this.plotlyChart = Plotly.newPlot(
          (this.$refs as any).plotlygraph,
          this.data.data,
          this.data.layout,
          plotlyConfig,
        )
      }

      if (this.autoscale) {
        Plotly.relayout((this.$refs as any).plotlygraph, {
          'xaxis.autorange': true,
          'yaxis.autorange': true,
        })
      }
    }
  }

  export default toNative(PlotlyView)
</script>
<style>
  .plotly {
    min-height: 600px;
    width: 100%;
    height: 600px;
  }

  .plotlyFullscreen {
    height: 100vh;
  }
</style>

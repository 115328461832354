import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

@Store()
export class RemoteStore extends BaseStore {
  public async sendBackupTrigger(uuid: string) {
    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${uuid}/notifications`,
        data: {
          message: {
            data: {
              'remote-command': 'oura://v1/backup?silent=true',
            },
          },
        },
      },
      'triggerBackup',
      uuid,
      null,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
  }

  public async sendRemoteMessage(uuid: string, title: string, body: string) {
    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${uuid}/notifications`,
        data: {
          message: {
            data: {
              'remote-command': `oura://v1/message?title=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`,
            },
          },
        },
      },
      'triggerBackup',
      uuid,
      null,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
  }

  public async sendRemoteCommand(uuid: string, command: string, message?: string) {
    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${uuid}/notifications`,
        data: {
          message: {
            data: {
              'remote-command': !message
                ? `oura://v1/${command}`
                : `oura://v1/confirm_scheme` +
                  `?scheme=${encodeURIComponent(`oura://v1/${command}`)}` +
                  `&title=${encodeURIComponent('Message from Oura support')}` +
                  `&body=${encodeURIComponent(message)}`,
            },
          },
        },
      },
      'triggerBackup',
      uuid,
      null,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
  }
}

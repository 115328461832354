<template>
  <div v-if="user">
    <v-row class="mt-0 mb-6">
      <v-col cols="12" md="9">
        <div class="d-flex text-h5 font-weight-light">
          <template v-if="lastBackup">Last backup was made {{ lastBackup }}</template>
          <template v-else>No backups have been made yet by the user</template>
        </div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="lastBackup">
            This means that backup files for last {{ sinceBackup }} is not available in Darwin
          </template>
          <template v-else>You can try to trigger a new backup if user has connected a ring to the app</template>
        </div>

        <div class="mt-4">
          <v-skeleton-loader v-if="isLoading" type="chip" color="transparent" class="ma-n4" />

          <template v-else>
            <v-chip v-if="hasIOSFiles" size="small" class="pa-4 ml-2" text="iOS" prepend-icon="mdi-apple" />
            <v-chip v-else-if="hasAndroidFiles" size="small" class="pa-4" text="Android" prepend-icon="mdi-android" />
            <v-chip v-else size="small" class="pa-4" text="No backups" prepend-icon="mdi-cloud-upload" />

            <v-chip v-if="hasResearchFiles" size="small" class="pa-4 ml-2" text="ORB data" prepend-icon="mdi-flask" />
          </template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="d-flex">
        <v-spacer />

        <v-btn class="mt-2" text="Trigger backup" color="primary" @click="triggerBackup()" />
      </v-col>
    </v-row>

    <v-stepper class="mb-8" :model-value="step">
      <v-stepper-header>
        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/data/cconfig'">
          <v-stepper-item
            editable
            value="cconfig"
            icon="mdi-eye"
            edit-icon="mdi-cellphone"
            :color="step === 'cconfig' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/data/cconfig'"
          >
            Client config
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/data/assa'">
          <v-stepper-item
            editable
            value="assa"
            icon="mdi-database"
            edit-icon="mdi-account"
            :color="step === 'assa' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/data/assa'"
          >
            ASSA viewer
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/data/debug'">
          <v-stepper-item
            editable
            value="debug"
            icon="mdi-bug-outline"
            edit-icon="mdi-chart-line"
            :color="step === 'debug' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/data/debug'"
          >
            Debug logs
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/data/streams'">
          <v-stepper-item
            editable
            value="streams"
            icon="mdi-flask"
            edit-icon="mdi-chart-line"
            :color="step === 'streams' ? 'info' : ''"
            :to="'/users/' + user?.uuid + '/data/assa'"
          >
            ORB streams
          </v-stepper-item>
        </router-link>

        <router-link style="color: var(--v-theme-on-surface)" :to="'/users/' + user.uuid + '/data/files'">
          <v-stepper-item
            editable
            value="files"
            icon="mdi-cloud-upload"
            edit-icon="mdi-cellphone"
            :color="step === 'files' ? 'info' : ''"
            :to="'/users/' + user.uuid + '/data/assa'"
            @click="refreshData()"
          >
            Backup files
          </v-stepper-item>
        </router-link>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { FilesStore, RemoteStore, UserStore } from '#stores'

  @Component
  class DataFilesSummary extends mixins(DateTime) {
    @Prop() public step!: string

    public userStore = new UserStore()

    protected readonly filesStore = new FilesStore()
    protected readonly remoteStore = new RemoteStore()

    public get user() {
      return this.userStore.user
    }

    public get isLoading() {
      return this.filesStore.waitingForData()
    }

    public get lastBackup() {
      return this.filesStore.files[0]?.lastModified
        ? this.formatDateTime(this.filesStore.files[0]?.lastModified, 'HH:mm on DD MMM YYYY')
        : ''
    }

    public get sinceBackup() {
      return this.$dayjs(this.filesStore.files[0]?.lastModified).fromNow(true)
    }

    public get hasIOSFiles() {
      return !!this.filesStore.files.find((f) => f.key.split('/')[2] === 'ios')
    }

    public get hasAndroidFiles() {
      return !!this.filesStore.files.find((f) => f.key.split('/')[2] === 'android')
    }

    public get hasResearchFiles() {
      return !!this.filesStore.files.find((f) => f.type === 'orb')
    }

    @Watch('user', { immediate: true })
    protected userChanged() {
      this.refreshData()
    }

    public refreshData() {
      if (this.user) {
        this.filesStore.listFiles(this.user.uuid)
      }
    }

    public async triggerBackup() {
      const confirm = await this.$confirm(
        'Confirm backup triggering',
        'This will trigger silent backup on users device. Please remember that application needs to be running and ' +
          'it takes couple minutes for the backup to complete. Files will be automatically refreshed in 5 minutes, but ' +
          'you can also refresh files just by clicking the "Backup files" tab again.',
      )

      if (confirm) {
        this.remoteStore.sendBackupTrigger(this.user!.uuid)

        this.$notify('Backup trigger sent succesfully')

        setTimeout(
          () => {
            this.refreshData()
          },
          5 * 60 * 1000,
        )
      }
    }
  }

  export default toNative(DataFilesSummary)
</script>
